<template>
<div>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu tras</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Menadżer wydruków</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/routes" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

    <!-- SEARCHER --> 
    <div class="min-w-0 flex-1 xl:col-span-6 pr-0.5 lg:pr-10 xl:pr-0">
        <div :class="{'flex items-center justify-between': true}">
            <div :class="{'w-full flex': true, 'justify-between': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                <div v-if="!this.showStatusFilters" class="w-2/3 sm:w-1/3 flex">
                    <label for="search" class="sr-only">Szukaj</label>
                    <div class="relative">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                    </div>
                    <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                </div>
                <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                    <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Status</label>
                                <select v-model="pickedStatus" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="all">Wszystkie</option>
                                    <option v-for="status in statuses.filter(el => el.id !== 3)" :value="status.id" :key="status.id">{{status.name}}</option>
                                </select>
                            </div>
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data wyjazdu</label>
                                    <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="pickedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                    <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                        <option value="creationDesc">Utworzono: Od najnowszych</option>
                                        <option value="creationAsc">Utworzono: Od najstarszych</option>
                                        <option value="returnDateAsc">Data powrotu: Od najwcześniejszych</option>
                                        <option value="returnDateDesc">Data powrotu: Od najpóźniejszych</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                                    <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                        <option value="all">Wszystkie</option>
                                        <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                                    </select>
                                </div>
                            <!-- <div class="transition ease-in-out duration-300 relative inline-flex items-center px-4 py-2 rounded-md border border-blue-500 bg-blue-500 text-sm font-medium text-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Zastosuj filtry</div> -->
                            </div>
                            <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                </div>
                <div v-if="searcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                    <div @click="showFilters()" class="mr-4 mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                        <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                        <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                    </div>
                </div>
            </div>
  
        </div>

        <div class="mt-4" v-if="searcher.found.length > 0">
            <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            </span>          
        </div>
    </div>

    <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Kraj</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Trasa</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Data wyjazdu</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Data powrotu</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Zamówienia</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Wartość trasy</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="route in searcher.found.length > 0 ? searcher.found : sortedRoutes" :key="route.id">
                            <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                <div class="inline-flex">
                                    <div v-if="route.country !== undefined">
                                        <img v-if="route.country.flagUrl.length>0" :src="route.country.flagUrl" v-tooltip="`${route.country.displayName}`" class="h-4 w-6 mr-1 border border-gray-300">
                                        <span v-if="route.country.flagUrl.length === 0">---</span>
                                    </div>
                                    <div v-else>
                                        <span>---</span>
                                    </div>
                                </div>
                            </td>
                            <td class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900">
                                <span class="inline-flex items-center rounded-md  px-2.5 py-0.5 text-xs font-medium " :class="{'bg-yellow-100 text-yellow-800': route.statusId === 0,'bg-yellow-100 text-yellow-800': route.statusId === 1, 'bg-blue-100 text-blue-800': route.statusId === 2, 'bg-green-100 text-green-800': route.statusId === 3}">
                                    {{ findStatus(route.statusId)?.name }}
                                </span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                <div class="flex gap-2 cursor-default">
                                    <TruckIcon class="w-6 h-6 text-gray-400 hover:text-gray-500 transition-all duration-300" v-tooltip="`Tablica rejestracyjna: ${route.vehicle.attribs.licensePlate.value} - Przestrzeń transportowa: ${route.vehicle.attribs.payloadCapacity.value} m3`" />
                                    <div class="inline-flex gap-2 items-center w-48">
                                        <span v-tooltip="`${route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}`" class="truncate font-semibold">{{route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span class="text-xs">{{ moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL") }}</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span class="text-xs">{{ moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL") }}</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div class="flex items-center ml-5 lg:ml-6">
                                    <span @click="ordersModal.orders = route.orders, ordersModal.route = route, ordersModal.open = true" class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400">{{route.orders.length}}
                                    </span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                <div class="inline-flex gap-1 items-center rounded-full py-0.5 text-xs ">
                                    <span :class="[getValues(route).length === 1 ? 'cursor-pointer' : 'cursor-default']" v-tooltip.top-start="getValues(route).length === 1 && `Kwota dostawy: ${createDeliveryPrice(route)}`"  v-for="manu in getValues(route) !== undefined ? getValues(route).slice(0,1) : ''" :key="manu">
                                        {{typeof manu === 'object' ? manu.name : manu}}
                                    </span>
                                    <span :class="[getValues(route) !== undefined ? 'cursor-pointer' : '', 'font-semibold']" v-tooltip="`${getValues(route) !== undefined ? getValues(route).slice(1).toString().replaceAll(',',' | ') : getValues(route)} + Kwota dostawy (${createDeliveryPrice(route)})`" v-if="getValues(route) !== undefined ? getValues(route).length - 1 > 0 : ''">
                                        + {{getValues(route) !== undefined ? getValues(route).length - 1 : ''}} więcej
                                    </span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap text-sm text-gray-400 hover:text-gray-600 transition-all duration-300">
                                <div class="flex justify-center border-r px-3">
                                    <PrinterIcon v-tooltip="`Kliknij, aby wydrukować listę dla kierowców.`" @click.prevent="printLabel(route)" class="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-4 text-sm text-gray-400 hover:text-gray-600 transition-all duration-300">
                                <PrinterIcon v-tooltip="`Kliknij, aby wydrukować listę załadunku.`" @click.prevent="printLoadPdf(route)" class="h-5 w-5 cursor-pointer" aria-hidden="true" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>

    <OrdersModal v-if="ordersModal.open" :route="ordersModal.route" :ordersRefs="ordersModal.orders" @close="ordersModal.open = false" :showBackButton="false" />
    <EmptyState class="mt-2 mb-2" v-if="routes.length === 0" />
    <div class="flex items-center justify-center mt-4" v-if="!queryLimitReached">
        <button type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..
        </button>
    </div> 
</div>
</template>
<script>
import { db } from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import { HomeIcon } from '@heroicons/vue/solid'
import { TruckIcon, SearchIcon, PlusSmIcon, XIcon , FilterIcon, PrinterIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import TableRoutesList from '../components/TableRoutesList.vue'
import OrdersModal from '../components/OrdersModal.vue'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
    data() {
        return {
            ordersModal:{
                open:false,
                orders: null,
            },
            queryNumber: 0,
            pickedStatus: "all",
            pickedCountry: 'all',
            sortName: "creationDesc",
            sortType: {
                order: "desc",
                type: "meta.createdDate"
            },
            showStatusFilters: false,
            modal: {
                open: false
            },
            moment: moment,
            routes: [],
            orders: [],
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            vehicles: {},
            statuses: [],
            queryLimit: 25,
            dbListener: null,
            query: null,
            queryLimitReached: false,
            pickedDateOfDelivery: null,
            pickedDateOfDeliveryUnix: null,
            countries: [
                {
                    displayName: 'Polska',
                    name: 'Poland',
                    code: 'PL',
                    flagUrl: 'https://flagcdn.com/w320/pl.png',
                },
                {
                    displayName: 'Niemcy',
                    name: 'Germany',
                    code: 'DE',
                    flagUrl: 'https://flagcdn.com/w320/de.png',
                },
                {
                    displayName: 'Francja',
                    name: 'France',
                    code: 'FR',
                    flagUrl: 'https://flagcdn.com/w320/fr.png',
                },
                {
                    displayName: 'Luksemburg',
                    name: 'Luxembourg',
                    code: 'LU',
                    flagUrl: 'https://flagcdn.com/w320/lu.png',
                },
                {
                    displayName: 'Włochy',
                    name: 'Italy',
                    code: 'IT',
                    flagUrl: 'https://flagcdn.com/w320/it.png',
                },
                {
                    displayName: 'Holandia',
                    name: 'Netherlands',
                    code: 'NL',
                    flagUrl: 'https://flagcdn.com/w320/nl.png',
                },
                {
                    displayName: 'Belgia',
                    name: 'Belgium',
                    code: 'BE',
                    flagUrl: 'https://flagcdn.com/w320/be.png',
                },
                {
                    displayName: 'Austria',
                    name: 'Austria',
                    code: 'AT',
                    flagUrl: 'https://flagcdn.com/w320/at.png',
                },
            ],
            storage: [],
            segments: [],
        }
    },
    components: { 
        TruckIcon, EmptyState, TableRoutesList, PrinterIcon, HomeIcon, SearchIcon, PlusSmIcon, XIcon, FilterIcon, OrdersModal
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
    computed:
    {
        sortedRoutes() {
            return this.routes.slice().sort((a, b) => {
                // Function to extract a number from titles starting with 'Trasa'
                const extractNumber = title => {
                    const match = title.match(/^Trasa (\d+)/);
                    return match ? parseInt(match[1], 10) : null;
                };

                // Extract numbers for comparison
                const numberA = extractNumber(a.title);
                const numberB = extractNumber(b.title);

                // Prioritize titles with 'Trasa' and a number
                if (numberA !== null && numberB !== null) {
                    return numberA - numberB;
                } else if (numberA !== null) {
                    return -1; // Titles with 'Trasa' come first
                } else if (numberB !== null) {
                    return 1; // Titles with 'Trasa' come first
                }

                // Fallback to alphabetical sorting or other logic for titles without 'Trasa'
                return a.title.localeCompare(b.title);
            });
        }
    },
    methods: {
        resetDateOfCreate()
        {
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
        },
        pickDateOfDelivery(event)
        {
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                show: true,
                head: "Popraw datę!",
                subheader: `Wykryto, że data jest niepoprawna.`,
                success: false
                });
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            }
            else
            {
                this.pickedDateOfDeliveryUnix = mDate.unix()
            }
        },
        async getVehicles(){
            let vehicleData = await db.collection('Vehicles').get()
            for (let i = 0; i < vehicleData.docs.length; i++) {
                const vehicle = vehicleData.docs[i];
                const vehicleToPush = vehicle.data()
                this.vehicles[vehicleToPush.id] = vehicleToPush
            }
        },
        async getStatuses()
        {
            let res = await db.collection("RouteStatuses").doc("StatusList").get()
            this.statuses = res.data().list
        },
        findStatus(statusId)
        {
            return this.statuses.find(el => el.id === statusId)
        },
        createDeliveryPrice(route)
        {
          let translatedListOfDelivery = [];
            if(route.value !== undefined)
            {
                if(route.value.delivery !== undefined)
                {
                    if(Object.keys(route.value.delivery).length > 0)
                    {
                        const values = Object.entries(route.value.delivery)
                        values.forEach(element => {
                            if(element[0])
                            {
                                translatedListOfDelivery.push(parseFloat(element[1].price).toFixed(2) + ' ' + element[0]);
                            }
                        })
                    }
                    else
                    {
                       translatedListOfDelivery.push('---'); 
                    }
                }
                else
                {
                    translatedListOfDelivery.push('---');
                }
            }
            else
            {
                translatedListOfDelivery.push('---');
            }
            return translatedListOfDelivery
        },
        getValues(route)
        {
            let translatedListOfValues = [];
            if(route.value !== undefined)
            {
                if(route.value.total !== undefined)
                {
                    const values = Object.entries(route.value.total)
                    values.forEach(element => {
                        if(element[0])
                        {
                            if(element[1].value !== 0)
                            {
                                translatedListOfValues.push(parseFloat(element[1].value).toFixed(2) + ' ' + element[0]);
                            }
                        }
                    })
                }
                else
                {
                    translatedListOfValues.push('Brak wartości trasy.');
                }
            }
            else
            {
                translatedListOfValues.push('Brak wartości trasy.');
            }
            return translatedListOfValues
        },
        async getRoutes()
        {
            this.query = null;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.query = db.collection("Routes")
            switch(this.pickedStatus)
            {
                case 'all':
                    this.query = this.query.where("statusId", "in", [1,2])
                    break;
                case 1:
                    this.query = this.query.where('statusId', "==", 1)
                    break;
                case 2:
                    this.query = this.query.where('statusId', "==", 2)
                    break;
                default:
                    this.query = this.query      
                    break;
            }

            if(this.pickedCountry !== 'all')
            {
                this.query = this.query.where('country.name', "==", this.pickedCountry)
            }

            if(this.pickedDateOfDeliveryUnix !== null){
                this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
                let startOfDayDelivery = this.pickedDateOfDeliveryUnix
                this.query = this.query.where('date.start', "==", moment.unix(startOfDayDelivery).toDate())
            }

            this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)

            const getOrdersQueryNumber = await this.query.get()
            if(!getOrdersQueryNumber.empty)
            {
                this.queryNumber = getOrdersQueryNumber.docs.length;
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.routes = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const data = querySnapshot.docs[i].data()
                    data.vehicle = this.vehicles[data.vehicle.id]
                    this.routes.push(data)
                }
            });
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 20;
            this.getRoutes()
        },
        loadMoreRoutes()
        {
            this.dbListener();
            this.continueListening();      
        },
        showFilters()
        {
            this.showStatusFilters = !this.showStatusFilters
            if(!this.showStatusFilters)
            {
                this.pickedStatus = 'all';
                this.pickedCountry = 'all';
                this.sortName = "creationDesc";
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                },
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null;
                this.pickStatus()
            }
        },
        pickStatus(){
            this.dbListener()
            this.getRoutes()
        },
        changeSort()
        {
            if(this.sortName === "creationAsc"){
                this.sortType = {
                    order: "asc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "creationDesc"){
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "returnDateAsc"){
                this.sortType = {
                    order: "asc",
                    type: "date.end"
                }
            }
            if(this.sortName === "returnDateDesc"){
                this.sortType = {
                    order: "desc",
                    type: "date.end"
                }
            }
        },
        selectOrders(country, codes)
        {
            const countryOrders = this.orders.filter(order => order.delivery.country.code === country);
            const groupOrders = []

            switch (country) {
                case 'PL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'DE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'FR':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'IT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'NL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'BE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'AT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                default:
                    console.log(`Country ${country} not supported`);
                    break;
            }

            return groupOrders
        },
        resetSearch()
        {  
            this.routes = [];
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.getRoutes()
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-routes",
                    collection: "Routes",
                    phrase: this.searcher.phrase,
                    searchFields: ['names', 'countries', 'brands', 'models', 'licensePlates', 'id']
                })
                if(res.data.success)
                {
                    let allFound = []
                    res.data.found.forEach((found) => {
                        let current = found
                        current.vehicle = this.vehicles[current.vehicle._path.segments[1]]
                        allFound.push(current);
                    });
                    this.searcher.found = allFound;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.getRoutes()
        },
        loadMoreProducts()
        {
            this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })
        },
        async printLabel(route)
        {
            try {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Generowanie listy dla kierowców...",
                    subheader: `Nie zamykaj tego okna.`,
                });
                let drivers = []
                route.drivers.forEach(driver => {
                    drivers.push(`${driver.name} ${driver.surname}`)
                })
    
                drivers = drivers.toString()
                drivers = drivers.replaceAll(',', ', \n')
    
                var dd = {
                    pageSize: 'A4',
                    content: [
                        {
                        columns: [
                            [
                            // {
                            //     text: `Trasa #${route.id}`,
                            //     color: '#333333',
                            //     width: '*',
                            //     fontSize: 12,
                            //     bold: true,
                            //     alignment: 'center',
                            //     margin: [0, 0, 0, 10],
                            // },
                            {
                                text: `${route.title}`,
                                color: '#333333',
                                width: '*',
                                fontSize: 16,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 0, 0, 10],
                            }
                            ],
                        ],
                        },
                        {
                        columns: [
                            {
                            text: 'Data trasy',
                            color: '#aaaaab',
                            bold: true,
                            fontSize: 12,
                            alignment: 'left',
                            margin: [0, 15, 0, 2],
                            },
                            {
                            text: 'Kierowcy',
                            color: '#aaaaab',
                            bold: true,
                            fontSize: 12,
                            alignment: 'left',
                            margin: [0, 15, 0, 2],
                            },
                        ],
                        },
                        {
                        columns: [
                            {
                            text: `Od: ${moment.unix(route.date.start.seconds).format('DD.MM.YYYY')}r \nDo: ${moment.unix(route.date.end.seconds).format('DD.MM.YYYY')}r`,
                            bold: true,
                            fontSize: 10,
                            color: '#333333',
                            alignment: 'left',
                            },
                            {
                            text: `${drivers} \n ${route.vehicle.brand} ${route.vehicle.model}  |  ${route.vehicle.attribs.licensePlate.value}`,
                            bold: true,
                            fontSize: 10,
                            color: '#333333',
                            alignment: 'left',
                            },
                        ],
                        },
                        '\n',
                        {
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function(i, node) {
                            return 1;
                            },
                            vLineWidth: function(i, node) {
                            return 1;
                            },
                            hLineColor: function(i, node) {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                            },
                            vLineColor: function(i, node) {
                            return '#eaeaea';
                            },
                            hLineStyle: function(i, node) {
                            // if (i === 0 || i === node.table.body.length) {
                            return null;
                            //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function(i, node) {
                            return 10;
                            },
                            paddingRight: function(i, node) {
                            return 10;
                            },
                            paddingTop: function(i, node) {
                            return 2;
                            },
                            paddingBottom: function(i, node) {
                            return 2;
                            },
                            fillColor: function(rowIndex, node, columnIndex) {
                            return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [1,50,50,50,'*',8,30,50],
                            body: [
                            [
                                {
                                    text: 'Lp.',
                                    border: [false, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Klient',
                                    fillColor: '#eaf2f5',
                                    alignment: 'center',
                                    border: [true, true, true, true],
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Dostawa',
                                    fillColor: '#eaf2f5',
                                    alignment: 'center',
                                    border: [true, true, true, true],
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Telefon',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Zamówienia',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Paczki',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Doręczenie',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                },
                                {
                                    text: 'Info',
                                    border: [true, true, true, true],
                                    alignment: 'center',
                                    fillColor: '#eaf2f5',
                                    margin: [-9, 14, -9, 5],
                                    fontSize: 8,
                                }
                            ],
    
                            ],
                        },
                        },
                    ],
                    footer: function(currentPage, pageCount) {
                        return {
                        columns: [
                            {
                                text: 'Strona ' + currentPage.toString() + ' z ' + pageCount,
                                alignment: 'left',
                                fontSize: 8
                            },
                            {
                                text: `Trasa #${route.id}`,
                                color: '#6b7280',
                                alignment: 'right',
                                fontSize: 8
                            },
                        ],
                        margin: [40, 20, 40, 40] // Margines na dole stopki
                        };
                    }, 
                    defaultStyle: {
                        columnGap: 20,
                    },
                };
    
                route.orders.sort((a,b) => a.index - b.index)
                // for(let i = route.orders.length - 1; i >= 0; i--)
                for(let i = 0; i < route.orders.length; i++)
                {
                    let ordersRef = (await route.orders[i].ref.get()).data()
                    // route.orders[i] = (await route.orders[i].get()).data()
    
                    let orders = []
                    let totalPackages = 0
                    let products = ""
                    let variantName = '';
    
                    const positions = Object.values(ordersRef.positions)
    
                    for(let y=0; y<positions.length; y++)
                    {
                        totalPackages += positions[y].product.amountOfPackages
    
                        let variantId = positions[y].product.variantId
                        let product = (await positions[y].product.ref.get()).data()
    
                        if(product !== undefined)
                        {
                            products = products.concat(`${positions[y].product.quantity}x ${product.name} \n (${product.variants[variantId] !== undefined ? product.variants[variantId].name : 'WYBRANY WARIANT ZOSTAŁ USUNIĘTY Z PRODUKTU W SYSTEMIE'}) \n`)
                        }
                        else
                        {
                            variantName += `${positions[y].product.quantity}x ${positions[y].product.name} \n`
                        }
                    }
    
                    for(let y = 0; y < positions.length; y++ )
                    {
                        orders.push(positions[y].product)
                    }
    
                    let address = []
    
                    if(ordersRef.delivery.address.length > 0)
                    {
                        address.push(ordersRef.delivery.address)
                    }
                    if(ordersRef.delivery.postCode.length > 0)
                    {
                        address.push(ordersRef.delivery.postCode)
                    }
    
                    if(ordersRef.delivery.city.length > 0)
                    {
                        address.push(ordersRef.delivery.city)
                    }
                    if(ordersRef.delivery.country.name.length > 0)
                    {
                        address.push(ordersRef.delivery.country.name)
                    }
    
                    address = address.toString()
                    address = address.replaceAll(',', ', \n')
    
                    moment.locale('pl');
    
                    let template = [{
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function(i, node) {
                            return 1;
                            },
                            vLineWidth: function(i, node) {
                            return 1;
                            },
                            hLineColor: function(i, node) {
                            // if (i === 0) {
                            //     return '#bfdde8';
                            // }
                            return '#eaeaea';
                            },
                            vLineColor: function(i, node) {
                            return '#eaeaea';
                            },
                            hLineStyle: function(i, node) {
                            // if (i === 0 || i === node.table.body.length) {
                            return null;
                            //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function(i, node) {
                            return 10;
                            },
                            paddingRight: function(i, node) {
                            return 10;
                            },
                            paddingTop: function(i, node) {
                            return 2;
                            },
                            paddingBottom: function(i, node) {
                            return 2;
                            },
                            fillColor: function(rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [1,50,50,50,'*',8,30,50],
                            body: [
                                [
                                    {
                                        text: `${i+1}`,
                                        border: [false, true, true, false],
                                        margin: [-9, 5, -5, 5],
                                        alignment: 'right',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: `${ordersRef.delivery.name}`,
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: `${address}`,
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: `${ordersRef.delivery.phone.full}`,
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: `${products + variantName}`,
                                        //   text: `${positions[i].product.name === undefined ? '?' : positions[i].product.name } szt.${positions[i].product.quantity} p.${positions[i].product.amountOfPackages}`,
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: `${totalPackages}`,
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        border: [true, true, true, false],
                                        text: ordersRef.delivery.date.planned != null && ordersRef.delivery.date.planned.toHourUnix != null && ordersRef.delivery.date.planned.fromHourUnix != null ? moment.unix(ordersRef.delivery.date.planned.unix).format('DD.MM.YYYY') + ' ' + (moment.unix(ordersRef.delivery.date.planned.fromHourUnix).format('HH:mm') + ' - ' + moment.unix(ordersRef.delivery.date.planned.toHourUnix).format('HH:mm')) : '---',
                                        fillColor: '#f5f5f5',
                                        margin: [-9, 5, -9, 5],
                                        alignment: 'center',
                                        fontSize: 8,
                                    },
                                    {
                                        alignment: 'center',
                                        qr: `${ordersRef.id}`,
                                        fit: '60',
                                        width: "*",
                                        // foreground: "#293980",
                                        fillColor: '#f5f5f5',
                                        margin: [0, 5, 0, 5],
                                        border: [true, true, true, false]
                                    },
                                ],
                            ],
                        },
                    },
                    {  
                        table: {
                            widths: ['*'],
                            body: [
                            ]
                        }
                    },
                    ]
                    template[1].table.body.push([
                        {
                            text: `Komentarz wewnętrzny: ${this.createCommentList(ordersRef).length !== 0 ? this.createCommentList(ordersRef) : '---'}`,
                            alignment: 'center',
                            fontSize: 8,
                            bold: true,
                            borderColor: ['#eaeaea', '#eaeaea', '#eaeaea', '#eaeaea'],
                        }
                    ], )
                    template[1].table.body.push([
                        {
                            text: `Komentarz zamówienia w trasie: ${ordersRef.description !== undefined ? ordersRef.description : '---'}`,
                            alignment: 'center',
                            fontSize: 8,
                            borderColor: ['#eaeaea', '#eaeaea', '#eaeaea', '#eaeaea'],
                        },
                    ]
                    )
                    dd.content.push(...template)
                }
                pdfMake.createPdf(dd).download(`trasa_${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}.pdf`);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem.",
                    subheader: `Lista dla kierowców została pomyślnie wygenerowana.`,
                    success: true
                });
                this.$store.commit('resetGlobalLoader');
            } 
            catch (error) {
                console.log(error);
                this.$store.commit('resetGlobalLoader');
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona niepowodzeniem.",
                    subheader: error,
                    success: true
                });
            }
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    if(element[1].infos.onOrderCreation.length > 0)
                    {
                        translatedListOfComments.push(element[1].infos.onOrderCreation);
                    }
                }
            })

            return translatedListOfComments
        },
        async printLoadPdf(route)
        {
            try {
                this.segments = [];
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Generowanie listy załadunku...",
                    subheader: `Nie zamykaj tego okna.`,
                });
                
                route.orders.sort((a,b) => a.index - b.index)

                const getStorage = await db.collection('Storage').get();
                for(let i=0; i<getStorage.docs.length; i++)
                {
                    let storage = getStorage.docs[i].data();
                    const getSegments = await db.collection('Storage').doc(storage.id.toString()).collection('Segments').get();
                    for(let y=0; y<getSegments.docs.length; y++)
                    {
                        let segment = getSegments.docs[y].data();
                        segment.warehouse = storage;
                        if(Object.keys(segment.products).length > 0)
                        {
                            this.segments.push(segment);
                        }
                    }
                };
    
                let dd = {
                    pageSize: 'A4',
                    content: [
                        {
                        columns: [
                            [
                                {
                                    text: `${route.title}`,
                                    color: '#333333',
                                    width: '*',
                                    fontSize: 16,
                                    bold: true,
                                    alignment: 'center',
                                    margin: [0, 0, 0, 10],
                                },
                                {
                                    text: `${route.vehicle.brand + ' ' + route.vehicle.model} | ${route.vehicle.attribs.licensePlate.value}`,
                                    color: '#333333',
                                    width: '*',
                                    fontSize: 12,
                                    bold: true,
                                    alignment: 'center',
                                    margin: [0, 0, 0, 10],
                                }
                            ],
                        ],
                        },
                        '\n',
                        {
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function(i, node) {
                            return 1;
                            },
                            vLineWidth: function(i, node) {
                            return 1;
                            },
                            hLineColor: function(i, node) {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                            },
                            vLineColor: function(i, node) {
                            return '#eaeaea';
                            },
                            hLineStyle: function(i, node) {
                            // if (i === 0 || i === node.table.body.length) {
                            return null;
                            //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function(i, node) {
                            return 10;
                            },
                            paddingRight: function(i, node) {
                            return 10;
                            },
                            paddingTop: function(i, node) {
                            return 2;
                            },
                            paddingBottom: function(i, node) {
                            return 2;
                            },
                            fillColor: function(rowIndex, node, columnIndex) {
                            return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: ['4%', '*', '20%', "7%", "13%"],
                            body: [
                            [
                                {
                                text: 'Lp.',
                                border: [true, true, true, true],
                                alignment: 'center',
                                margin: [0, 7, 0, 7],
                                fillColor: '#F4F4F4',
                                textTransform: 'uppercase',
                                fontSize: 10,
                                bold: true
                                },
                                {
                                text: 'Nazwa mebla',
                                border: [true, true, true, true],
                                alignment: 'center',
                                margin: [0, 7, 0, 7],
                                fillColor: '#F4F4F4',
                                textTransform: 'uppercase',
                                fontSize: 10,
                                bold: true
                                },
                                {
                                text: 'Klient',
                                border: [true, true, true, true],
                                alignment: 'center',
                                margin: [0, 7, 0, 7],
                                fillColor: '#F4F4F4',
                                textTransform: 'uppercase',
                                fontSize: 10,
                                bold: true
                                },
                                {
                                text: 'L. pacz.',
                                border: [true, true, true, true],
                                alignment: 'center',
                                margin: [-4, 7, -4, 7],
                                fillColor: '#F4F4F4',
                                textTransform: 'uppercase',
                                fontSize: 10,
                                bold: true
                                },
                                {
                                text: 'Dostawa',
                                border: [true, true, true, true],
                                alignment: 'center',
                                margin: [-4, 7, -4, 7],
                                fillColor: '#F4F4F4',
                                textTransform: 'uppercase',
                                fontSize: 10,
                                bold: true
                                },
                            ],
    
                            ],
                        },
                        },
                    ],
                    styles: {
                        },
                       
                    defaultStyle: {
                        columnGap: 20,
                    },
                };
    
                for(let i = route.orders.length - 1; i >= 0; i--)
                {
                    let ordersRef = (await route.orders[i].ref.get()).data()
    
                    let products = ""
                    let totalPackages = 0
                    let variantName = '';
                    let partLocation = ''
    
                    const positions = Object.values(ordersRef.positions)
         
                    for(let y=0; y<positions.length; y++)
                    {
                        let product = (await positions[y].product.ref.get()).data()
                        let isFound = false;
                        totalPackages += product.variants[positions[y].product.variantId].amountOfPackages
                        let variantId = positions[y].product.variantId
                        if(product !== undefined)
                        {
                            products = products.concat(`${positions[y].product.quantity}x ${product.name} \n (${product.variants[variantId] !== undefined ? product.variants[variantId].name : 'WYBRANY WARIANT ZOSTAŁ USUNIĘTY Z PRODUKTU W SYSTEMIE'}) \n`)
                        }
                        else
                        {
                            variantName += `${positions[y].product.quantity}x ${positions[y].product.name} \n`
                        }

                        if(product.variants[variantId].parts != null)
                        {
                            const parts = Object.entries(product.variants[variantId].parts);
                            for(let p=0; p<parts.length; p++)
                            {
                                let foundOrder = false;
                                for(let k=0; k<this.segments.length; k++)
                                {
                                    if(foundOrder) break;
                                    if(this.segments[k].products[product.id])
                                    {
                                        const partInSegment = Object.entries(this.segments[k].products[product.id])
                                        for(let x=0; x<partInSegment.length; x++)
                                        {
                                            if(partInSegment[x][0].includes(variantId))
                                            {
                                                if(parts[p][1].name === partInSegment[x][1].part)
                                                {
                                                    if(partInSegment[x][1].order == null)
                                                    {
                                                        partInSegment[x][1].order = [];
                                                    }
                                                    else if(partInSegment[x][1].order.length === 0)
                                                    {
                                                        partInSegment[x][1].order = [];
                                                    }
                                                    for(let o=0; o<partInSegment[x][1].order.length; o++)
                                                    {
                                                        if(partInSegment[x][1].order[o] === ordersRef.id)
                                                        {
                                                            partInSegment[x][1].order.splice(o, 1)
                                                            isFound = true;
                                                            foundOrder = true;
                                                            partLocation = partLocation.concat(`\n ${partInSegment[x][1].part !== undefined ? `'${partInSegment[x][1].part}'` : 'BRAK NAZWY PACZKI'}, MAGAZYN: '${this.segments[k].warehouse !== undefined ? this.segments[k].warehouse.name : '---'}', SEGMENT: '${partInSegment[x][1].segment != null ? partInSegment[x][1].segment.name : 'BRAK'}'`)
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if(!isFound)
                        {
                            partLocation = partLocation.concat(`\n ${ordersRef.statusId === 7 ? 'PACZKA ZOSTAŁA ZAŁADOWANA' : 'BRAK ZAREZERWOWANYCH SZTUK W MAGAZYNIE'}`)
                        }
                    }

                    let template = [{
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function(i, node) {
                            return 1;
                            },
                            vLineWidth: function(i, node) {
                            return 1;
                            },
                            hLineColor: function(i, node) {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                            },
                            vLineColor: function(i, node) {
                            return '#eaeaea';
                            },
                            hLineStyle: function(i, node) {
                            // if (i === 0 || i === node.table.body.length) {
                            return null;
                            //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function(i, node) {
                            return 10;
                            },
                            paddingRight: function(i, node) {
                            return 10;
                            },
                            paddingTop: function(i, node) {
                            return 2;
                            },
                            paddingBottom: function(i, node) {
                            return 2;
                            },
                            fillColor: function(rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: ['4%', '*', '20%', "7%", "13%"],
                            body: [
                                [
                                    {
                                    text: `${route.orders.length-i}`,
                                    border: [false, true, true, false],
                                    margin: [-9, 5, -5, 5],
                                    alignment: 'right',
                                    fontSize: 10,
                                    },
                                    {
                                    border: [true, true, true, false],
                                    text: `${products + variantName}`,
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 10,
                                    },
                                    {
                                    border: [true, true, true, false],
                                    text: `${ordersRef.client.name}`,
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 10,
                                    },
                                    {
                                    border: [true, true, true, false],
                                    text: `${totalPackages}`,
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 10,
                                    },
                                    {
                                    border: [true, true, true, false],
                                    text: `${ordersRef.delivery.date.confirmed !== null ? ordersRef.delivery.date.confirmed.asText : ordersRef.delivery.date.suggested !== null ? ordersRef.delivery.date.suggested.asText : 'Nie wskazano'}`,
                                    fillColor: '#f5f5f5',
                                    margin: [-9, 5, -9, 5],
                                    alignment: 'center',
                                    fontSize: 10,
                                    },
                                ],
                            ],
                        },
                    },
                    { 
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function(i, node) {
                            return 1;
                            },
                            vLineWidth: function(i, node) {
                            return 1;
                            },
                            hLineColor: function(i, node) {
                            if (i === 1 || i === 0) {
                                return '#bfdde8';
                            }
                            return '#eaeaea';
                            },
                            vLineColor: function(i, node) {
                            return '#eaeaea';
                            },
                            hLineStyle: function(i, node) {
                            // if (i === 0 || i === node.table.body.length) {
                            return null;
                            //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function(i, node) {
                            return 10;
                            },
                            paddingRight: function(i, node) {
                            return 10;
                            },
                            paddingTop: function(i, node) {
                            return 2;
                            },
                            paddingBottom: function(i, node) {
                            return 2;
                            },
                            fillColor: function(rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        }, 
                        table: {
                            widths: ['*'],
                            body: [
                            ]
                        }
                    },
                    ]
                    template[1].table.body.push([
                        {
                            text: `Komentarz wewnętrzny: ${this.createCommentList(ordersRef).length !== 0 ? this.createCommentList(ordersRef) : '---'}`,
                            alignment: 'center',
                            fontSize: 8,
                            bold: true
                        }
                    ], )
                    template[1].table.body.push([
                        {
                            text: `Komentarz zamówienia w trasie: ${ordersRef.description !== undefined ? ordersRef.description : '---'}`,
                            alignment: 'center',
                            fontSize: 8,
                        },
                    ])
                    template[1].table.body.push([
                      {
                        text: `LOKALIZACJA PACZEK: ${partLocation}`,
                        alignment: 'center',
                        fontSize: 8,
                      },
                   ])
                    dd.content.push(...template)
                };

                pdfMake.createPdf(dd).download(`załadunek_${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}.pdf`);

                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem.",
                    subheader: `Lista załadunkowa została pomyślnie wygenerowana.`,
                    success: true
                });
                this.$store.commit('resetGlobalLoader');
            }
            catch (error) {
                console.log(error);
                this.$store.commit('resetGlobalLoader');
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona niepowodzeniem.",
                    subheader: error,
                    success: true
                });
            }
        },
    },
    async created()
    {
        moment.locale('pl')
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes) {
            this.$router.push("/hub")
        }
        await this.getVehicles()
        await this.getStatuses()
        await this.getRoutes();
        const statuses = await db.collection("RouteStatuses").get()

        statuses.forEach(statusesList => {
          this.statuses = statusesList.data().list
        })
    },
}
</script>
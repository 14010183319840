<template>
  <div class="border-b border-gray-200 pb-5 mt-5 select-none mb-4">
    <div class="flex sm:items-baseline sm:justify-between">
      <div class="sm:w-0 sm:flex-1 w-full">
        <h1 id="message-heading" class="text-base font-semibold leading-6 text-gray-900">Witaj {{this.$store.state.user.displayName}}, </h1>
        <!-- {{this.route.title}} -->
        <span class="text-xs font-medium">Trasa: {{this.route.title.length === 0 ? "---" : this.route.title}}</span>
        <div>   
            <span class="text-xs font-medium block">Kraj: {{this.route.country.displayName}}</span>
        </div>
      </div>

      <div class="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
        <Menu as="div" class="relative ml-3 inline-block text-left">
          <div>
            <MenuButton class="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-md">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1 text-xs mt-1">
                <div class="relative">
                  <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center">
                    <span class="bg-white px-2 text-xs text-gray-500">Tryb offline</span>
                  </div>
                </div>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <div @click.prevent="openAlertModal(null, 'accept')"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex justify-between px-4 py-2 text-sm cursor-pointer']">
                    <span>Zapisz stan w urządzeniu</span>
                  </div>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <div @click.prevent="performOfflineQueueManually()"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex justify-between px-4 py-2 text-sm cursor-pointer']">
                    <span>Wymuś przesłanie kolejki ({{$store.state.offlineModeState.callsQueue.length}})</span>
                  </div>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <div @click.prevent="openAlertModal(order, 'reset')"  :class="[active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'flex justify-between px-4 py-2 text-sm cursor-pointer']">
                    <span>Resetuj zapis offline</span>
                  </div>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <div @click.prevent="clearOfflineQueue()"  :class="[active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'flex justify-between px-4 py-2 text-sm cursor-pointer']">
                    <span>Wyczyść kolejkę offline ({{$store.state.offlineModeState.callsQueue.length}})</span>
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>

  <div class="relative inline-block align-middle self-center rounded-lg text-left transform transition-all duration-300 w-full">
      <LoadingSpinnerHub v-if="loading"/>
      <div class="z-10 flex-1 " v-auto-animate>
        <div v-if="orders.length && !loading" class="duration-300 transition-all sm:rounded-md overflow-visible">
          <!-- BACK -->
          <div class="hidden md:flex md:justify-end md:pb-4" v-if="$store.state.isDriverOnline">
              <button @click="close()" type="button" class="transition-all duration-300 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Wróć
                  <ReplyIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </button>
          </div>
          <div :ref="`refOrder-${order.id}`" v-for="(order, index) in orders" :key="index" :class="[scannedOrder && scannedOrder.id === order.id ? 'border-2 border-green-400' : 'border', order.statusId === 300 ? 'bg-gradient-to-r from-red-50 to-red-300' : '', order.isScan && order.confirmation.status === 0 ? 'bg-gradient-to-r from-gray-50 to-gray-300' : '', order.statusId === 6 ? 'bg-gradient-to-r from-green-100 to-green-200' : '', 'relative select-none flex flex-col sm:flex-row w-full rounded-lg border-gray-200 mb-5 bg-white shadow-md']">

            <!-- STATUSES -->
            <div v-if="order.statusId === 300" class="absolute top-0 right-0 bottom-0 left-0 m-auto w-60 h-16 z-50">
              <div class="flex flex-col justify-center items-center">
                <LockClosedIcon @click="clientAvailable(order)" class="transition-all duration-300 w-8 h-8 hover:text-gray-400 text-gray-600 cursor-pointer" />
                <span class="text-center font-bold text-gray-700 ">Nie zastano klienta.</span>
                <span class="mb-2 text-xs font-medium text-gray-500">{{order.client.name}}</span>
                <span class="text-center text-sm font-medium text-gray-500 whitespace-nowrap">Aby odblokować zamówienie, kliknij ikonę kłódki.</span>
              </div>
            </div>
            <div v-if="order.isScan && order.confirmation.status === 0" class="absolute top-0 right-0 bottom-0 left-0 m-auto w-64 h-28 z-50">
              <div class="flex flex-col justify-center items-center">
                <span class="text-center font-bold text-gray-700 whitespace-nowrap">Wprowadź 4-cyfrowy kod odbioru</span>
                <div class="mt-2 w-full">
                  <input @change="fixConfirmationCode(confirmationCode, $event)" type="number" maxlength="4" min="0" max="9999" name="confirmation-code" id="confirmation-code" v-model="confirmationCode" class="text-center block w-full rounded-md border-0 py-3.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="XXXX" />
                </div>
                
                <div class="mt-4 flex flex-row gap-6">
                  <button @click.prevent="confirmationChange('accept', order)" :disabled="this.confirmationCode.length === 0" type="button" :class="[this.confirmationCode.length === 0 ? 'opacity-50 pointer-events-none' : '', 'transition-all duration-300 inline-flex items-center gap-x-2 rounded-md bg-indigo-200 px-5 py-4 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Zatwierdź
                  </button>
                  <button @click.prevent="confirmationChange('skip', order)" type="button" :class="['transition-all duration-300 inline-flex items-center gap-x-2 whitespace-nowrap rounded-md bg-red-200 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                    <ExclamationIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Brak kodu
                  </button>
                </div>
              </div>
            </div>
            <div v-if="order.statusId === 6" class="absolute top-0 right-0 bottom-0 left-0 m-auto w-60 h-16 z-50">
              <div class="flex flex-col justify-center items-center">
                <CheckIcon class="transition-all duration-300 w-11 h-11" />
                <span class="mb-2 text-xs font-medium text-gray-500">{{order.client.name}}</span>
                <span class="text-center font-bold text-gray-700 whitespace-nowrap">Zamówienie zostało dostarczone.</span>
              </div>
            </div>
          
            <div :class="[order.statusId === 300 || order.statusId === 6 || (order.isScan && order.confirmation.status === 0) ? 'opacity-20 pointer-events-none' : '', 'flex text-xs sm:text-sm']">
              <div class="sm:space-y-4 px-3 py-2 h-full grid sm:flex sm:flex-col" :class="[order.chatOpened ? 'grid-cols-3' : 'grid-cols-4']">
                <!-- OPTIONS FOR CHAT -->
                <div @click="openChat(order)" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2 text-gray-500 hover:text-gray-400']">
                  <span :class="['font-medium']">
                    <ChatAltIcon v-if="!order.chatOpened" :class="['w-5 h-5']" />
                    <ReplyIcon v-if="order.chatOpened" :class="['w-5 h-5']" />
                  </span>
                  <span :class="['font-medium md:whitespace-nowrap']">
                    <span v-if="!order.chatOpened">Przejdź do SMS</span>
                    <span v-if="order.chatOpened">Powrót</span>
                  </span>
                </div>
                <div @click="order.halfHourBlocked || order.sending ? '' : setMessageType(order, 'halfHour')" v-if="order.chatOpened" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2', order.messageType === 'halfHour' ? 'text-green-500 hover:text-green-400' : 'text-gray-500 hover:text-gray-400', order.halfHourBlocked || order.sending ? 'opacity-30 cursor-not-allowed hover:text-gray-500' : '']">
                  <span :class="['font-medium']">
                    <ChatAltIcon :class="['w-5 h-5']" />
                  </span>
                  <span v-auto-animate :class="['font-medium md:whitespace-nowrap']">
                    <span>Za 30 min. dostawa</span>
                  </span>
                </div>
                <div @click="order.changeDeliveryTimeBlocked || order.sending ? '' : setMessageType(order, 'changeDeliveryTime')" v-if="order.chatOpened" :class="['cursor-pointer col-span-1 relative transition-all duration-300 group flex flex-col border-l-4 py-2 px-2 text-gray-500', order.messageType === 'changeDeliveryTime' ? 'text-green-500 hover:text-green-400' : 'text-gray-500 hover:text-gray-400', order.changeDeliveryTimeBlocked || order.sending ? 'opacity-30 cursor-not-allowed hover:text-gray-500' : '']">
                  <span :class="['font-medium']">
                    <ChatAltIcon :class="['w-5 h-5']" />
                  </span>
                  <span v-auto-animate :class="['font-medium md:whitespace-nowrap']">
                    <span>Zmiana godziny dostawy</span>
                  </span>
                </div>

                <!-- OPTIONS -->
                <router-link @click.prevent="!order.isScan && route.statusId === 2 ? $store.state.callForScan = {order: order, taken: false} : ''" v-if="!order.chatOpened" :to="!order.isScan && route.statusId === 2 ?`/dashboard/drivers/qr?id=${order.id}` : ''" :class="['col-span-1 relative flex flex-col']">
                  <div :class="[order.isScan ? 'opacity-20 pointer-events-none border-green-400' : '', 'text-gray-500 border-l-4 py-2 px-2 font-semibold md:whitespace-nowrap hover:text-indigo-400 transition-all duration-300']">
                    <QrcodeIcon class="w-5 h-5" />
                    Zeskanuj paczkę
                  </div>
                  <div v-if="order.isScan" class="absolute top-0 right-0 bottom-0 left-0 m-auto h-8 z-50">
                    <div class="flex justify-center items-center">
                      <CheckCircleIcon class="transition-all duration-300 w-7 h-7 hover:text-gray-400 text-gray-600 cursor-pointer" />
                    </div>
                  </div>
                </router-link>
                <!-- <div @click.prevent="skipScanProcess(order.id, order)" :class="[order.isScan ? 'opacity-20 pointer-events-none border-green-400' : '', 'text-gray-500 border-l-4 py-2 px-2 font-semibold md:whitespace-nowrap hover:text-indigo-400 transition-all duration-300']">
                  <QrcodeIcon class="w-5 h-5" />
                  Pomiń skanowanie
                </div> -->
                <router-link @click.prevent="order.isScan && !order.isPhoto && route.statusId === 2 ? $store.state.callForPhotos = {order: order, taken: false} : ''" :to="order.isScan && !order.isPhoto && route.statusId === 2 ? `/dashboard/drivers/photo/${order.id}` : ''" v-if="!order.chatOpened" :class="['col-span-1 relative flex flex-col']">
                  <div :class="[order.isPhoto ? 'opacity-20 pointer-events-none border-green-400' : '', order.confirmation.status === 0 ? 'opacity-20 pointer-events-none' : '', !order.isScan && !order.isPhoto ? 'opacity-20 pointer-events-none' : '', 'text-gray-500 border-l-4 py-2 px-2 font-semibold md:whitespace-nowrap hover:text-indigo-400 transition-all duration-300']">
                    <PhotographIcon class="h-5 w-5" />
                    Zrób zdjęcie
                  </div>
                  <div v-if="order.isPhoto" class="absolute top-0 right-0 bottom-0 left-0 m-auto h-8 z-50">
                    <div class="flex justify-center items-center">
                      <CheckCircleIcon class="transition-all duration-300 w-7 h-7 hover:text-gray-400 text-gray-600 cursor-pointer" />
                    </div>
                  </div>
                </router-link>
                <router-link @click.prevent="order.isScan && order.isPhoto && !order.isSign ? $store.state.callForSignature = {order: order, taken: false} : ''" :to="order.isScan && order.isPhoto && !order.isSign ? `/dashboard/drivers/signature/${order.id}` : ''" v-if="!order.chatOpened" :class="['col-span-1 relative flex flex-col']">
                <div :class="[order.isSign ? 'opacity-20 pointer-events-none border-green-400' : '', !order.isSign && !order.isPhoto ? 'opacity-20' : '', 'text-gray-500 border-l-4 py-2 px-2 font-semibold md:whitespace-nowrap hover:text-indigo-400 transition-all duration-300']">
                  <span>
                    <PencilAltIcon class="h-5 w-5" />
                    Podpis
                  </span>
                </div>
                <div v-if="order.isSign" class="absolute top-0 right-0 bottom-0 left-0 m-auto h-8 z-50">
                  <div class="flex justify-center items-center">
                    <CheckCircleIcon class="transition-all duration-300 w-7 h-7 hover:text-gray-400 text-gray-600 cursor-pointer" />
                  </div>
                </div>
                </router-link>

              </div>
            </div>
            <!-- MENU -->
            <div v-if="!order.chatOpened" :class="[order.statusId === 300 || order.statusId === 6 || (order.statusId === 5 && order.isScan && order.confirmation.status === 0) ? 'opacity-10 pointer-events-none' : '', ' w-full sm:border-l border-gray-200']">
              <div class="relative overflow-hidden ">
                <dl class="divide-y divide-gray-100 px-3 pb-5 text-sm leading-6">
                  <div class="flex justify-between gap-x-4 py-3">
                    <dt class="text-sm font-medium leading-6 text-gray-900 select-none" v-for="prod in createProductList(order).slice(0,1)" :key="prod.id">
                      {{prod}} 
                      <div :class="order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? 'bg-green-50 text-green-500' : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? 'bg-blue-50 text-blue-500' : 'bg-red-50 text-red-500'" class="rounded-md px-2.5 py-1 bg-gray-100 text-gray-800 text-xs font-medium w-fit">{{order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? "Potwierdzono dostawę" : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? "Wysłano potwierdzenie" : "Nie wysłano potwierdzenia"}}</div>
                    </dt>
                    <dd class="text-gray-700">
                      <Menu as="div" :class="['relative inline-block text-left']" >
                        <div>
                          <MenuButton class="flex items-start rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span class="sr-only">Open options</span>
                            <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                          <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="py-1">
                              <MenuItem  @click="clientNotAvailable(order)" v-slot="{ active }">
                                <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'transition-all duration-150 group flex items-center px-4 py-2 text-sm']">
                                  <BanIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                  Nie zastano klienta 
                                </a>
                              </MenuItem>
                            </div>
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <a target="_blank" :href="`https://www.google.pl/maps/dir//${order.delivery.address},+${order.delivery.postCode}+${order.delivery.city}/`" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'transition-all duration-150 group flex items-center px-4 py-2 text-sm']">
                                  <LocationMarkerIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                  Prowadź do celu
                                </a>
                              </MenuItem>
                            </div>
                            <div class="py-1" v-if="order.client.phone.full.length > 0">
                              <MenuItem v-slot="{ active }">
                                <a target="_blank" :href="order.client.phone.full.length > 0 ? `tel:${order.client.phone.full}` : ''" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'transition-all duration-150 group flex items-center px-4 py-2 text-sm']">
                                  <PhoneIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                  <span> {{order.client.phone.full.length > 0 ? `Zadzwoń ${order.client.phone.full}` : 'Brak numeru telefonu'}}</span>
                                </a>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </dd>
                  </div>
                  <div>
                  <div class="flex justify-start gap-x-4 py-3 text-left">
                    <dt class="text-gray-500 text-xs select-none">Dane osobowe:</dt>
                  </div>
                  <dd class="flex justify-start text-gray-700">
                      <span class="font-medium text-gray-700">{{ order.client.name }}</span>
                    </dd>
                  </div>
                  <div>
                  <div class="flex justify-start gap-x-4 py-3 ">
                    <dt class="text-gray-500 text-xs select-none">Adres dostawy:</dt>
                  </div>
                    <dd class="flex justify-start gap-x-2">
                      <div class="font-medium text-gray-700">{{ order.delivery.address }}</div>
                    </dd>
                  </div>
                  <div>
                  <div class="flex justify-start gap-x-4 py-3">
                    <dt class="text-gray-500 text-xs select-none">Miasto dostawy:</dt>
                  </div>
                  <dd class="flex justify-start">
                      <div class="font-medium text-gray-700">{{ order.delivery.city }}</div>
                    </dd>
                  </div>
                  <div>
                  <div class="flex justify-start gap-x-4 py-3">
                    <dt class="text-gray-500 text-xs select-none">Godzina dostawy:</dt>
                  </div>
                  <dd class="flex justify-start">
                      <div class="font-medium text-gray-700">{{ order.delivery.date.planned != null && order.delivery.date.planned.fromHourUnix != null && order.delivery.date.planned.toHourUnix != null ? moment.unix(order.delivery.date.planned.fromHourUnix).format("HH:mm") + ' - ' + moment.unix(order.delivery.date.planned.toHourUnix).format('HH:mm')  : '--' }}</div>
                    </dd>
                  </div>
                </dl>
          </div>
        </div>
        <!-- CHAT -->
        <div v-if="order.chatOpened" class="w-full">
          <div class="text-xs sm:text-sm flex flex-col sm:border-l border-gray-200 h-full px-3 pt-2 divide-y divide-gray-200 w-full">
            <div class="text-sm font-medium leading-6 text-gray-900 select-none">
              {{order.client.name}} <span v-if="order.client.phone.full.length > 0">- {{order.client.phone.full}}</span>
            </div>
            <div v-auto-animate :id="`chatarea${index}`" class="flex flex-col-reverse font-medium leading-6 text-gray-900 select-none w-full gap-2 py-2 h-72 overflow-y-scroll overflow-x-hidden">
              <div v-if="order.loading" class="flex flex-col w-full h-full items-center justify-center whitespace-nowrap">
                <LoadingSpinnerHub />
              </div>
              <div v-if="!order.loading && order.messages.length === 0" class="flex flex-col w-full h-full items-center justify-center text-gray-400 whitespace-nowrap" >
                <MailOpenIcon class="h-8 w-8" />
                Brak wiadomości
              </div>
              <div v-if="!order.loading && order.messages.length > 0" v-auto-animate v-for="(message, index) in order.messages" :class="message.direction === 'outcoming' ? 'justify-end' : ''" class="flex w-full">
                <div class="md:w-4/5 flex" :class="message.direction === 'outcoming' ? 'justify-end' : ''">
                  <div v-if="message.direction === 'incoming'" class="h-full flex items-end pr-1">
                    <div class="h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-gray-400">
                      <UserIcon class="h-5 w-5" />
                    </div>
                  </div>
                  <div @click="message.translate = !message.translate" class="flex flex-col cursor-pointer">
                    <div :class="message.direction === 'outcoming' ? 'bg-indigo-600 text-white' : 'bg-gray-200'" class="py-2 px-2 break-words rounded-xl">{{message.translate ? message.polishMessage : message.message}}</div>
                    <div v-if="message.direction === 'outcoming'" class="text-xxs text-right">{{moment.unix(message.meta.lastModificationDate.seconds).format("DD-MM-YYYY")}} <span :class="message.status > 3 ? 'text-red-500 font-bold' : ''"> {{smsStatuses[message.status].name}}</span></div>
                  </div>
                  <div v-if="message.direction === 'outcoming'" class="h-full flex items-end pl-1">
                    <div class="h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-gray-400">
                      <img class="h-7 w-7 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full items-center">
              <div v-auto-animate class="w-full py-2 flex">
                <div class="text-gray-400 flex items-center w-full" v-if="order.messageType === ''">Tutaj pojawi się wybrana wiadomość</div>
                <div class="flex items-center w-full" v-if="order.messageType === 'halfHour'">Twoje zamówienie zostanie dostarczone w ciągu 30 minut.</div>
                <div class="flex items-center w-full" v-if="order.messageType === 'changeDeliveryTime'">Przepraszamy za utrudnienia. Nowa godzina dostawy:  
                  <div class="datePickerDiv">
                    <VueDatePicker v-model="order.selectedTime" hide-input-icon auto-apply time-picker :clearable="false" locale="pl"/>
                  </div>                           
                  .
                </div>
              </div>
              <div v-if="!order.sending" @click="order.messageType == '' ? '' : openAlertModal(order, 'send')" class="pl-2 py-2 flex items-end"><PaperAirplaneIcon :class="[order.messageType === '' ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:text-indigo-500 cursor-pointer']" class="h-6 w-6 sm:h-8 sm:w-8 transform rotate-90" /></div>
              <div v-if="order.sending" class="spinner ml-2 my-2"></div>
            </div>
          </div>
        </div>
        <!-- <button v-if="allOrdersDelivered" @click="finishRoute" type="button" class="transition-all duration-300 inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Zakończ trasę
        </button> -->
      </div>
    </div>
  </div>
  </div>
  <AlertModal v-if="alertModal.open" @closeModal="alertModal.open = false" @accept="sendMessage()" :modalData="alertModal.data" buttonColor="indigo" iconColor="indigo"></AlertModal>
  <AlertModal v-if="alertModalCreateOfflineBackup.open" @closeModal="alertModalCreateOfflineBackup.open = false" @accept="saveOfflinePersistState()" :modalData="alertModalCreateOfflineBackup.data" buttonColor="indigo" iconColor="indigo"></AlertModal>
  <AlertModal v-if="alertModalclearOfflinePersistState.open" @closeModal="alertModalclearOfflinePersistState.open = false" @accept="clearOfflinePersistState()" :modalData="alertModalclearOfflinePersistState.data"></AlertModal>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import { PencilAltIcon, MailIcon, PhotographIcon, CheckIcon, LockOpenIcon, QrcodeIcon, LocationMarkerIcon, ReplyIcon, ChatAltIcon, MailOpenIcon} from '@heroicons/vue/outline'
import { ChevronDownIcon, XIcon, DotsVerticalIcon, LockClosedIcon, BanIcon, CheckCircleIcon, UserIcon, PaperAirplaneIcon, PhoneIcon, ExclamationIcon } from '@heroicons/vue/solid'

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, MenuButton, MenuItem, MenuItems, Menu } from '@headlessui/vue';
import AlertModal from './AlertModal.vue'
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import scrollIntoView from 'scroll-into-view-if-needed'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import smsTemplates from '../smsTemplates.js'
moment.locale('pl');
export default {
  components: {
    ExclamationIcon, MenuButton, PhoneIcon, CheckCircleIcon, LocationMarkerIcon,  BanIcon, LockClosedIcon, QrcodeIcon, MenuItem, MenuItems, Menu, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, LoadingSpinnerHub, PencilAltIcon, MailIcon, PhotographIcon, ChevronDownIcon, XIcon,CheckIcon,LockOpenIcon, scrollIntoView, DotsVerticalIcon, ReplyIcon, ChatAltIcon, UserIcon, PaperAirplaneIcon, AlertModal, VueDatePicker, MailOpenIcon
  },
  props: ['ordersProp', 'route', 'scannedOrder'],
  emits:['closeModal', 'getRoutes'],
  data() {
    return {
      open: true,
      orders: [],
      loading: false,
      // offline backup settings
      backup: {
          interval: 5000,
          ref: null,
      },
      totalOrders: 0,
      loadedOrders: 0,
      selectedOrder: null,
      alertModal: {
        open: false,
        data: {
          headerText: "Wysyłanie wiadomości",
          bodyText: "Czy na pewno chcesz wysłać wiadomość? Po wysłaniu wiadomości nie można już jej usunąć.",
          acceptButtonText: "Wyślij"
        }
      },
      alertModalCreateOfflineBackup: {
        open: false,
        data: {
          headerText: "Zapisywanie danych w urządzeniu",
          bodyText: "Czy na pewno chcesz zapisywać te dane na urządzeniu? Po zapisaniu danych nie będzie możliwe ich usunięcie.",
          acceptButtonText: "Zapisz"
        }
      },
      alertModalclearOfflinePersistState: {
        open: false,
        data: {
          headerText: "Resetowanie zapisu offline",
          bodyText: "Czy na pewno chcesz zresetować zapis offline? Po zresetowaniu, wykonane wcześniej akcje zostaną usunięte.",
          acceptButtonText: "Resetuj"
        }
      },
      confirmationCode: '',
      moment: moment
    }
  },
  async mounted()
  {
    if(this.$store.state.offlineModeState.callsQueue.length > 0)
    {
      this.performOfflineQueueManually()
    }
    if(this.$store.state.offlineModeState.lastSaveUnix === null)
    {
      await this.standardRouteInit()
    }
    else
    {
      // if route is the same as saved in backup and also online
      if((this.route.id === this.$store.state.offlineModeState.dataSnapshot.route.id) && this.$store.state.isDriverOnline)
      {
        await this.standardRouteInit()
      }
      else if((this.route.id !== this.$store.state.offlineModeState.dataSnapshot.route.id) && this.$store.state.isDriverOnline)
      {
        await this.standardRouteInit()
      }
      // if route is the same as saved in backup and is offline
      else if ((this.route.id === this.$store.state.offlineModeState.dataSnapshot.route.id) && !this.$store.state.isDriverOnline)
      {
        this.offlineRouteInit();
      }
      //if(this.$store.state.offlineModeState)
    }
    
    if(this.scannedOrder !== null)
    {
      setTimeout(() => {
        this.scrollToElement()
      }, 1000);
    }
    await this.getSMSHelpers();
  },
  beforeUnmount() {
    this.orders.forEach(order => {
      if(order.dbListener !== null) {
        order.dbListener()
        order.dbListener = null 
      }
    })
    // Clear offline mode backup interval
    clearInterval(this.backup.ref);
    this.backup.ref = null;
  },
  methods: {
    async skipScanProcess(id, order)
    {
      try {

        let firebasePayload = {};

        if(this.$store.state.isDriverOnline)
        {
          const getOrder = await db.collection('Orders').doc(id).get();
          const order = getOrder.data();
          
          order.packages.forEach(pack => {
            pack.scanned = true;
          });

          await db.collection('Orders').doc(id).update({
            packages: order.packages
          });
          await this.getOrdersInfo();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona pomyślnie!",
            subheader: 'Pominięto skanowanie uszkodzonych paczek.',
            success: true
          });
        }
        else
        {
          order.packages.forEach(pack => {
            pack.scanned = true;
          });

          firebasePayload = {
            "packages": order.packages
          }
       
          this.$store.commit("addToOfflineCallsQueue",
          {
            done: false,
            callClient: "firebase",
            call: {
              firebase: {
                ref: {
                  collection: "Orders",
                  doc: id 
                },
                action: "update", // set or update
                payload: {...firebasePayload} // body
              },
              axios: null,
            },
            routeId: this.route.id,  // id lub null
            orderId: id, // id lub null
            originalFunction: {
              name: "skipScanProcess", // nazwa oryginalnej funkcji
              payload: order // oryginalne argumenty wykonywanej funkcji 
            }
          })
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona pomyślnie!",
            subheader: '',
            success: true
          });
        }
      } 
      catch (error) {
        console.log(error);  
      }
    },
    saveOfflinePersistState()
    {
      this.createOfflineBackupSnapshot();
      this.$store.commit('setNotification',{
          show: true,
          head: "Zapisano dane na urządzeniu..",
          subheader: ``,
          success: true
      });
    },
    performOfflineQueueManually()
    {
      if(!this.$store.state.isDriverOnline) return false;
      this.$store.commit("performOfflineQueue");
      this.$store.commit('setNotification',{
          show: true,
          head: "Nawiązano próbę wysłania danych..",
          subheader: ``,
          success: true
      });
    },
    clearOfflinePersistState()
    {
      this.$store.commit('clearPersistStateDriver');
      this.$store.commit('setNotification',{
          show: true,
          head: "Wyczyszczono pamięć podręczną.",
          subheader: ``,
          success: true
      });
    },
    clearOfflineQueue()
    {
      this.$store.commit('clearOfflineQueue');
      this.$store.commit('setNotification',{
          show: true,
          head: "Wyczyszczono kolejkę zdarzeń offline.",
          subheader: `Zmiany, które wydarzyły się podczas trybu offline nie zostaną przesłane.`,
          success: true
      });
    },
    async offlineRouteInit()
    {
      if(this.$store.state.offlineModeState.dataSnapshot.route === null) return;
      if(this.$store.state.offlineModeState.dataSnapshot.orders === null) return;
      if((this.route.id !== this.$store.state.offlineModeState.dataSnapshot.route.id)) return;

      if(this.$store.state.isDriverOnline)
      {
        // in case internet is available now
        await this.standardRouteInit();
      }
      else
      {
        this.orders = [...this.$store.state.offlineModeState.dataSnapshot.orders];
        if(this.$store.state.callForScan.taken)
        {
          for(let i=0; i<this.orders.length; i++)
          {
            if(this.$store.state.callForScan.order.id === this.orders[i].id)
            {
              this.orders[i].isScan = true;
              this.createOfflineBackupSnapshot();
              break;
            }
          };
        };
        if(this.$store.state.callForPhotos.taken)
        {
          for(let i=0; i<this.orders.length; i++)
          {
            if(this.$store.state.callForPhotos.order.id === this.orders[i].id)
            {
              this.orders[i].isPhoto = true;
              this.createOfflineBackupSnapshot();
              break;
            }
          };
        };
        if(this.$store.state.callForSignature.taken)
        {
          for(let i=0; i<this.orders.length; i++)
          {
            if(this.$store.state.callForSignature.order.id === this.orders[i].id)
            {
              this.orders[i].isSign = true;
              this.orders[i].statusId = 6;
              this.createOfflineBackupSnapshot();
              break;
            }
          };
        };
        this.totalOrders = this.$store.state.offlineModeState.dataSnapshot.totals.all;
        this.loadedOrders = this.$store.state.offlineModeState.dataSnapshot.totals.loaded;
      }
    },
    async standardRouteInit()
    {
      await this.getOrdersInfo();
      // Set offline backup interval
      this.backup.ref = setInterval(() => {
        this.createOfflineBackupSnapshot()
      }, this.backup.interval )
    },
    // Offline backup creation (triggered on interval)
    createOfflineBackupSnapshot()
    {
        this.$store.commit('offlineModeSnapshotDriver', {
          route: this.route,
          orders: this.orders,
          totalOrdersAmount: this.totalOrders,
          loadedOrdersAmount: this.loadedOrders
        });
    },
    async confirmationChange(type, order)
    {
      try {
        let firebasePayload = {}
        if(type === 'accept')
        {
          firebasePayload = {
            "confirmation.status": 1
          }
        }
        else if(type === 'skip')
        {
          firebasePayload = {
            "confirmation.status": 2
          }
        };
        if(this.$store.state.isDriverOnline)
        {
          if(type === 'accept')
          {
            if(this.confirmationCode !== order.confirmation.code) throw 'Podany kod odbioru jest nieprawidłowy.';
            await db.collection('Orders').doc(order.id).update({
              "confirmation.status": 1
            })
            await this.getOrdersInfo();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona pomyślnie!",
              subheader: 'Otrzymany kod jest poprawny.',
              success: true
            });
          }
          else if(type === 'skip')
          {
            await db.collection('Orders').doc(order.id).update({
              "confirmation.status": 2
            })
            await this.getOrdersInfo();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona pomyślnie!",
              subheader: '',
              success: true
            });
          }
        }
        else
        {
          if(type === 'accept')
          {
            order.confirmation.status = 1
            if(this.confirmationCode !== order.confirmation.code) throw 'Podany kod odbioru jest nieprawidłowy.';
          }
          else if(type === 'skip')
          {
            order.confirmation.status = 2
          };
          this.$store.commit("addToOfflineCallsQueue",
          {
            done: false,
            callClient: "firebase",
            call: {
              firebase: {
                ref: {
                  collection: "Orders",
                  doc: order.id 
                },
                action: "update", // set or update
                payload: {...firebasePayload} // body
              },
              axios: null,
            },
            routeId: this.route.id,  // id lub null
            orderId: order.id, // id lub null
            originalFunction: {
              name: "confirmationChange", // nazwa oryginalnej funkcji
              payload: order // oryginalne argumenty wykonywanej funkcji 
            }
          })
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona pomyślnie!",
            subheader: '',
            success: true
          });
        }
      }
      catch (error) {
        console.log(error);
        this.confirmationCode = '';
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: error,
          success: false
        });
      }
    },
    fixConfirmationCode(val, event)
    {
      const value = event.target.value
      if(parseInt(value) < 0)
      {
        this.confirmationCode = '';
      }
      else if(value.length > 4)
      {
        let code = value.slice(0, 4)
        this.confirmationCode = parseInt(code)
      }
    },
    openAlertModal(order, type) {
      if(type === 'send')
      {
        this.alertModal.open = true
        this.selectedOrder = order
      } else if(type === 'accept')
      {
        this.alertModalCreateOfflineBackup.open = true
      } else if(type === 'reset')
      {
        this.alertModalclearOfflinePersistState.open = true
      }
    },
    openChat(order) {
      if(order.chatOpened) {
        order.chatOpened = false
        order.messageType = ''
        order.dbListener()
        order.dbListener = null
      } else {
        order.chatOpened = true
        this.downloadSMSChat(order)
      }
    },
    async getSMSHelpers() {
      this.smsStatuses = (await db.collection("SMSHelpers").doc("helpers").get()).data().statuses
    },
    async downloadSMSChat(order) {
      order.loading = true
      order.dbListener = db.collection("SMSList").where('orderId', '==', order.id).orderBy("meta.createdDate", "desc")
      .onSnapshot((querySnapshot) => {
        let messages = [];
        order.halfHourBlocked = true
        order.changeDeliveryTimeBlocked = true
        let changeDeliveryTimeFound = false
        let changeDeliveryTimeBlocked = false
        let halfHourFound = false
        let halfHourBlocked = false
        querySnapshot.forEach((doc) => {
          if(doc.data().type == '1' && (doc.data().status == '1' || doc.data().status == '2' || doc.data().status == '3')) {
            changeDeliveryTimeBlocked = true
            changeDeliveryTimeFound = true
          } else if(doc.data().type == '1') {
            changeDeliveryTimeFound = true
          }
          if(doc.data().type == '2' && (doc.data().status == '1' || doc.data().status == '2' || doc.data().status == '3')) {
            halfHourBlocked = true
            halfHourFound = true
          } else if(doc.data().type == '2') {
            halfHourFound = true
          }
          doc.data().translate = false
          messages.push(doc.data());
        });

        if(!changeDeliveryTimeFound || (changeDeliveryTimeFound && !changeDeliveryTimeBlocked)) {
          order.changeDeliveryTimeBlocked = false
        }

        if(!halfHourFound || (halfHourFound && !halfHourBlocked)) {
          order.halfHourBlocked = false
        }

        order.messages = messages
        setTimeout(() => {
          order.loading = false
        }, 200);
      });
    },
    async sendMessage() {
      try {
        this.selectedOrder.sending = true
        let data = {
          messageType: this.selectedOrder.messageType,
          prefix: this.selectedOrder.client.phone.prefix,
          time: `${this.selectedOrder.selectedTime.hours < 10 ? '0'+this.selectedOrder.selectedTime.hours : this.selectedOrder.selectedTime.hours}:${this.selectedOrder.selectedTime.minutes < 10 ? '0'+this.selectedOrder.selectedTime.minutes : this.selectedOrder.selectedTime.minutes}`
        }
        const template = smsTemplates(data);
        data.prefix = "+48"
        const polishTemplate = smsTemplates(data)
        if(template.length === 0) throw "Nie znaleziono szablonu wiadomości"

        if(this.$store.state.isDriverOnline)
        {
          await axios.post(`${this.$store.state.apiLink}/sms/send`, {
            to: this.selectedOrder.client.phone.full,
            type: this.selectedOrder.messageType === 'halfHour' ? 2 : 1,
            message: template,
            polishMessage: polishTemplate,
            orderId: this.selectedOrder.id
          })
        }
        else
        {
          this.$store.commit("addToOfflineCallsQueue",
          {
            done: false,
            callClient: "axios",
            call: {
              firebase: null,
              axios: {
                config: {
                  method: 'post',
                  url: `${this.$store.state.apiLink}/route/packages`,
                  data: {
                    to: this.selectedOrder.client.phone.full,
                    type: this.selectedOrder.messageType === 'halfHour' ? 2 : 1,
                    message: template,
                    polishMessage: polishTemplate,
                    orderId: this.selectedOrder.id
                  }
                }
              }
            },
            routeId: this.route.id,
            orderId: this.selectedOrder.id,
            originalFunction: {
              name: "",
              payload: null
            }
          })
        }
        
        this.selectedOrder.sending = false
        
        this.selectedOrder.messageType = ""
        this.selectedOrder.selectedTime = {
          hours: parseInt(moment().format('HH')),
          minutes: parseInt(moment().format('mm')),
          seconds: 0
        }
        this.selectedOrder = null
      } catch(error) {
        console.log(error);
        this.selectedOrder.sending = false
      }
    },
    setMessageType(order, type) {
      if(type === order.messageType) {
        order.messageType = ""
      } else {
        order.messageType = type
      }
    },
    async updateVehicleBooking() {
      const vehicleDoc = db.collection("Vehicles").doc(this.route.vehicle.id)
      const vehicle = (await vehicleDoc.get()).data()
      const updatedBookings = vehicle.bookings.filter(el => el.route.id !== this.route.id)
      await db.collection("Vehicles").doc(this.route.vehicle.id).update({"bookings": updatedBookings})
    },
    async updateDriversSchedules() {
       for(let driver of this.route.drivers) {
        const driverDoc = db.collection("UsersData").doc(driver.id)
        //get a driver data from db becuase driver data from a route object is irrelevant (it doesn't update in any way)
        driver = (await driverDoc.get()).data()
        const updatedSchedules = driver.routeSchedules.filter(el => el.route.id !== this.route.id);
        await driverDoc.update({"routeSchedules": updatedSchedules})
      }
    },
    scrollToElement() {
      const el = this.$refs[`refOrder-${this.scannedOrder.id}`][0];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    calculatePackages(order)
    {
      this.totalOrders++
      if(order.statusId === 7){
        this.loadedOrders++
      }
    },
    createProductList(order)
    {
        const products = Object.entries(order.positions)
        let translatedListOfProducts = [];
        products.forEach(element => {
            if(element[0])
            {
                translatedListOfProducts.push(element[1].product.name);
            }
        })

        return translatedListOfProducts
    },
    async clientNotAvailable(order)
    {
      const firebasePayload = {
        "statusId": 300,
      };
      if(this.$store.state.isDriverOnline)
      {
        // when online
        await db.collection("Orders").doc(order.id).update(firebasePayload)
        await this.$store.dispatch('updateHistory', {
          collectionName: "Orders",
          docId: order.id,
          message: "Zablokowano zamówienie.",
          routeId: this.route.id,
          details: [this.route.id]
        })
        this.getOrdersInfo('empty');
      }
      else
      {
        // when offline
        this.$store.commit("addToOfflineCallsQueue",
        {
          done: false,
          callClient: "firebase",
          call: {
            firebase: {
              ref: {
                collection: "Orders",
                doc: order.id 
              },
              action: "update", // set or update
              payload: {...firebasePayload} // body
            },
            axios: null,
          },
          routeId: this.route.id,  // id lub null
          orderId: order.id, // id lub null
          originalFunction: {
            name: "clientNotAvailable", // nazwa oryginalnej funkcji
            payload: order // oryginalne argumenty wykonywanej funkcji 
          }
        })

        let historyPayload = {
          message: "Zablokowano zamówienie.",
          details: [this.route.id],
          routeId: this.route.id,
          meta: {
            createdDate: moment().toDate(),
            lastModificationDate: moment().toDate(),
            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
            userId: this.$store.state.userData.id,
          }
        }

        this.$store.commit("addToOfflineCallsQueue", {
          done: false,
          callClient: "firebase",
          call: {
            firebase: {
              ref: {
                collection: "Orders",
                doc: order.id,
              },
              action: "set",
              payload: historyPayload
            },
            axios: null,
          },
          routeId: this.route.id,
          orderId: order.id,
          originalFunction: {
            name: "updateHistory",
            payload: historyPayload
          }
        })
        order.statusId = 300;
      }
    },
    async clientAvailable(order)
    {
      const firebasePayload = {
        "statusId": 5
      };
      
      if(this.$store.state.isDriverOnline)
      {
        // when online
        const orderRef = db.collection("Orders").doc(order.id)
        await orderRef.update(firebasePayload)

        await this.$store.dispatch('updateHistory', {
          collectionName: "Orders",
          docId: order.id,
          message: "Odblokowano zamówienie.",
          routeId: this.route.id,
          details: [this.route.id]
        });

        this.getOrdersInfo('unlock');
      }
      else
      {
        // when offline

        this.$store.commit("addToOfflineCallsQueue",
        {
          done: false,
          callClient: "firebase",
          call: {
            firebase: {
              ref: {
                collection: "Orders",
                doc: order.id 
              },
              action: "update", 
              payload: {...firebasePayload} 
            },
            axios: null,
          },
          routeId: this.route.id,  
          orderId: order.id, 
          originalFunction: {
            name: "clientAvailable", 
            payload: order 
          }
        })

        let historyPayload = {
          message: "Odblokowano zamówienie.",
          details: [this.route.id],
          routeId: this.route.id,
          meta: {
            createdDate: moment().toDate(),
            lastModificationDate: moment().toDate(),
            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
            userId: this.$store.state.userData.id,
          }
        };

        this.$store.commit("addToOfflineCallsQueue", {
          done: false,
          callClient: "firebase",
          call: {
            firebase: {
              ref: {
                collection: "Orders",
                doc: order.id,
              },
              action: "set",
              payload: historyPayload
            },
            axios: null,
          },
          routeId: this.route.id,
          orderId: order.id,
          originalFunction: {
            name: "updateHistory",
            payload: historyPayload
          }
        })
        order.statusId = 5;
      }
    },
    async finishRoute()
    {
      let statusPayload = {statusId: 3}
      if(this.$store.state.isDriverOnline)
      {
        const routeDoc = db.collection("Routes").doc(this.route.id)
        await routeDoc.update(statusPayload)
      }
      else
      {
        this.$store.commit("addToOfflineCallsQueue",
        {
          done: false,
          callClient: "firebase",
          call: {
            firebase: {
              ref: {
                collection: "Routes",
                doc: this.route.id 
              },
              action: "update", 
              payload: {...statusPayload} 
            },
            axios: null,
          },
          routeId: this.route.id,  
          orderId: null, 
          originalFunction: {
            name: "finishRoute", 
            payload: null
          }
        })

        this.route.statusId = 3;       
      }



      for(let order of this.orders)
      {
        if(order.statusId === 300)
        {
          if(order.recentStatusId === undefined)
          {
            let payload = {
              statusId: 3,
              packages: null,
              description: order.description + ', ' + 'zamówienie nie zostało dostarczone.',
            }

            if(this.$store.state.isDriverOnline)
            {
              await db.collection("Orders").doc(order.id).update(payload)
            }
            else
            {
                this.$store.commit("addToOfflineCallsQueue",
                {
                  done: false,
                  callClient: "firebase",
                  call: {
                    firebase: {
                      ref: {
                        collection: "Orders",
                        doc: order.id
                      },
                      action: "update", 
                      payload: {...payload} 
                    },
                    axios: null,
                  },
                  routeId: this.route.id,  
                  orderId: order.id, 
                  originalFunction: {
                    name: "finishRoute", 
                    payload: null
                  }
                })

                order.statusId = 3;
                order.packages = null;
                order.description = order.description + ', ' + 'zamówienie nie zostało dostarczone.';
            };


            
          }
          else
          {

            let payload = {
              statusId: order.recentStatusId,
              packages: null,
              description: order.description + ', ' + 'zamówienie nie zostało dostarczone.',
            }

            if(this.$store.state.isDriverOnline)
            {
              await db.collection("Orders").doc(order.id).update(payload);
              await this.$store.dispatch('updateHistory', {
                collectionName: "Orders",
                docId: order.id,
                message: "Zamówienie wróciło do magazynu.",
                routeId: this.route.id,
                details: [this.route.id]
              })
            }
            else
            {
              this.$store.commit("addToOfflineCallsQueue",
              {
                done: false,
                callClient: "firebase",
                call: {
                  firebase: {
                    ref: {
                      collection: "Orders",
                      doc: order.id
                    },
                    action: "update", 
                    payload: {...payload} 
                  },
                  axios: null,
                },
                routeId: this.route.id,
                orderId: order.id, 
                originalFunction: {
                  name: "finishRoute", 
                  payload: null
                }
              })

              order.statusId = order.recentStatusId;
              order.packages = null;
              order.description = order.description + ', ' + 'zamówienie nie zostało dostarczone.';

    
              let historyPayload = {
                message: "Zamówienie wróciło do magazynu.",
                details: [this.route.id],
                routeId: this.route.id,
                meta: {
                  createdDate: moment().toDate(),
                  lastModificationDate: moment().toDate(),
                  createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                  userId: this.$store.state.userData.id,
                }
              };

              this.$store.commit("addToOfflineCallsQueue", {
                done: false,
                callClient: "firebase",
                call: {
                  firebase: {
                    ref: {
                      collection: "Orders",
                      doc: order.id,
                    },
                    action: "set",
                    payload: historyPayload
                  },
                  axios: null,
                },
                routeId: this.route.id,
                orderId: order.id,
                originalFunction: {
                  name: "updateHistory",
                  payload: historyPayload
                }
              })
            }

            
          }

        }
      }
      // remove driver's booking
      // await this.updateDriversSchedules()
      // remove vehicle's booking
      // await this.updateVehicleBooking()

      if(this.this.$store.state.isDriverOnline)
      {
        this.$emit("getRoutes")
      }
      
      this.$store.state.route = null;
      this.close()
    },
    async getOrdersInfo(value)
    {
      this.loading = true;
      this.orders = [];
      let ordersRefs = [];

      for(let i=0; i<this.ordersProp.length; i++)
      {
        ordersRefs.push(this.ordersProp[i].id)
      }

      const result = await axios.post(`${this.$store.state.apiLink}/route/packages`, {
        orders: ordersRefs,
      })

      if(result.data.success && result.data.orders.length > 0)
      {
        result.data.orders.forEach(order => {
          order.chatOpened = false
          order.messageType = ""
          order.selectedTime = {
            hours: parseInt(moment().format('HH')),
            minutes: parseInt(moment().format('mm')),
            seconds: 0
          },
          order.messages = []
          order.dbListener = null
          order.loading = true
          order.halfHourBlocked = true
          order.changeDeliveryTimeBlocked = true
          order.sending = false
        })
        this.orders = result.data.orders;
        this.totalOrders = result.data.total.totalOrders;
        this.loadedOrders = result.data.total.loadedOrders;
        if(value === 'empty')
        {
          this.$store.commit('setNotification',{
              show: true,
              head: "Zamówienie zostało zablokowane.",
              subheader: ``,
              success: true
          });
        }
        else if(value === 'unlock')
        {
          this.$store.commit('setNotification',{
              show: true,
              head: "Zamówienie zostało odblokowane.",
              subheader: ``,
              success: true
          });
        }

        // CREATE OFFLINE MODE SNAPSHOT
        this.createOfflineBackupSnapshot()

        this.loading = false;
      }

    },
    emitClose()
    {
      this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
        this.$store.state.route = null;
    },
  },
  computed: {
    allOrdersDelivered()
    {
      for(let order of this.orders)
      {
        if(order.statusId !== 6 && order.statusId !== 300)
        {
          return false
        }
      }
      return true
    },
    progress()
    {
      if(this.totalOrders === 0)
      {
        return 0
      }
      return (this.loadedOrders/this.totalOrders)*100
    }
  },
}
</script>

<style scoped>
  * {
      -webkit-tap-highlight-color: transparent;
  }

  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }

.datePickerDiv :deep() input {
    border: 1px solid gray;
    background: transparent;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 3rem;
    margin: 0px;
    padding: 0px !important;
    text-align: center;
    text-transform: uppercase;
    user-select: none !important;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.datePickerDiv:hover :deep() input {
    background: #f3f4f6;
}
.datePickerDiv :deep() input::selection {
    background: transparent;
}

  .spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3.8px solid #4f46e5;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
          spinner-oaa3wk 2.2399999999999998s infinite linear;
  }

  @media (min-width: 640px) {
    .spinner {
      width: 32px;
      height: 32px;
    }
  }

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>